import { API } from 'aws-amplify';

import exports from './amplify-exports';

const configureAmplify = () => {
  const apiGateways = exports?.aws_cloud_logic_custom;
  if (!apiGateways) {
    throw new Error('API unknown');
  }

  API.configure({
    endpoints: [
      {
        name: 'default',
        endpoint: apiGateways[0].endpoint,
        region: apiGateways[0].region,
      },
    ],
  });
};

export default configureAmplify;
