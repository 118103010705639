// WARNING: DO NOT EDIT.  This file is automatically generated
// Written by aws-amplify-serverless-plugin/1.4.1 on 2024-11-14T19:01:30.102Z

const awsmobile = {
    aws_cloud_logic_custom: [
        {
            endpoint: 'https://qy97ii8mn2.execute-api.eu-central-1.amazonaws.com/prod',
            name: 'ApiGatewayRestApi',
            region: 'eu-central-1'
        }
    ],
    aws_project_region: 'eu-central-1'
};

export default awsmobile;
